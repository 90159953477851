import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Thailand",
  "subtitle": "October 2018",
  "category": "Asia"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.grab.com/kh/en/"
        }}>{`GRAB`}</a>{` - Basically Uber`}</li>
      <li parentName="ul">{`Local SIM - `}<a parentName="li" {...{
          "href": "https://www.ais.th/en/consumers/package/international/tourist-plan"
        }}>{`AIS tourist SIM`}</a>{` from the airport`}</li>
    </ul>
    <h2 {...{
      "id": "bangkok"
    }}>{`Bangkok`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XkANo7UB8QtD5vaP9"
        }}>{`The Bangkok Heritage`}</a>{` - Good food but recommend sharing with a friend as the portions are not exactly small`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QshQ2mHD9RHHH6RRA"
        }}>{`Chatuchak Weekend Market`}</a>{` - Lots of street food and shopping (all have similar prices)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/u8dYkiANpCRuMg5e9"
        }}>{`Pai Spa`}</a>{` - Thai massage with herbal compress was nice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wWfAEz4HN6LXaQ8A8"
        }}>{`Siam Discovery`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Food court on 2F requires a card to order (and then returned)`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/RMBi1jX5T6W9GQKH6"
            }}>{`Siam Square`}</a>{` - All the shopping you need`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/W98dV7LhFrLXcHfH7"
        }}>{`Wat pho`}</a>
        <ul parentName="li">
          <li parentName="ul">{`⚠️ Be careful of anyone who tells you the temple is closed for a few hours and proceed to offer you some boat ride!`}</li>
          <li parentName="ul">{`⚠️ Remember to dress appropriately`}</li>
          <li parentName="ul">{`Huge reclining Buddha but crowded. Purchase a stack of coins at the entrance to drop in the 108 bronze bowls along the wall.`}</li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "excursions"
    }}>{`Excursions`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nRHkB5g2N8C6CF6SA"
        }}>{`Amphawa Floating Market`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Amphawa_Floating_Market"
        }}>{`wiki`}</a>{`)`}
        <ul parentName="li">
          <li parentName="ul">{`Only on Friday, Saturday, and Sunday`}</li>
          <li parentName="ul">{`Famous for seafood; Try the Giant Prawns! I also had great Scallops`}</li>
          <li parentName="ul">{`My tour included a night time firefly boat tour which was really nice but mosquito repellent is a must!`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/BC7t234PyTGcuToN9"
        }}>{`Maeklong Railway Market`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Maeklong_Railway"
        }}>{`wiki`}</a>{`)`}
        <ul parentName="li">
          <li parentName="ul">{`It's crazy to me that the train runs through the market. However, it's a very unique experience to see everyone move out of the way for it to pass through!`}</li>
          <li parentName="ul">{`There's were so many Chinese tourist that the vendors speak better Chinese than English`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "koh-samui"
    }}>{`Koh Samui`}</h2>
    <ul>
      <li parentName="ul">{`GRAB charges an extra fee here but it's better than the taxi monopoly since they charge quite a bit even to go a small distance`}</li>
    </ul>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/6brfRXXSJyPGxy1m8"
        }}>{`Art Cafe by June`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/wyjpjfAR2Fv5exkFA"
        }}>{`Coco Tam’s`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Fire show at night in front of the beach (was Monday for me)`}</li>
          <li parentName="ul">{`The tables right of the DJ booth had the best view (at the time)`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PG1RxWr6KiuH9ToTA"
        }}>{`Lek thai seafood`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MdJkmB7LYdJ53Q5m8"
        }}>{`Fisherman’s Village Bophut`}</a></li>
    </ul>
    <h4 {...{
      "id": "scuba-diving"
    }}>{`Scuba diving`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/j6mR6GUJi2WD3fyu5"
        }}>{`Silent Divers`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Offers Nitrox`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/t26nierTR72trhUV7"
            }}>{`Sail Rock`}</a>{` - Hope to see a whale shark!`}</li>
          <li parentName="ul">{`Koh Tao - `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/R7Wxm63YNSAaGLeC9"
            }}>{`HTMS Sattakut Dive Site`}</a>{` (shipwreck)`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/w3A5K4p3La51ari4A"
            }}>{`Chomphun Pinnacle`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "chiang-mai"
    }}>{`Chiang Mai`}</h2>
    <p>{`One of my favorite cities`}</p>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/RNWmGfn2Jv6nyBiz5"
        }}>{`Huen Muan Jai`}</a>{` - Try the Northern Curry with a side of sticky rice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/N193XX622nyB26JP8"
        }}>{`Huen Phen`}</a>{` - Best Khao Soi I had`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/psh4ohGvmwwneFwe9"
        }}>{`Mr. Kai Restaurant`}</a>{` - I had the Squid Khao Soi with a fried egg`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ZNcjSsrUvVViT8tC7"
        }}>{`Cabaret Show`}</a>{` - Inside Anusarn Market`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/U1UWyY7gBBe7JZ9n6"
        }}>{`Elephant Nature Park`}</a>
        <ul parentName="li">
          <li parentName="ul">{`No riding park for rescued elephants. Highly highly recommend!`}</li>
          <li parentName="ul">{`Northern Thai Cooking Course - Green Mango Salad, Khao Soi, Larb Muang Moo, and Khao Tom Madd`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PCEnNmstMWSrbzsk7"
        }}>{`Fah Lanna Spa`}</a>{` - Hands down, best spa!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/dAfzVrmmYRhYy2QM6"
        }}>{`Small House Thai Cooking (Class)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Arm is a great guy! Around the corner from him, it's possible to buy a 1L cup of thai ice tea (I asked)`}</li>
          <li parentName="ul">{`My experience was with a very small 10 person group and 3 elephants. We fed, bathe, and walked them around.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/E1VBgc7vRdqnnfjw6"
        }}>{`Tha Phae Walking Street`}</a>{` - Sunday only with lots of street food. Night market starts at 5pm?`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/WBm3EtCbVHzH3QFaA"
        }}>{`Tops Supermarket`}</a></li>
    </ul>
    <h2 {...{
      "id": "chiang-rai"
    }}>{`Chiang Rai`}</h2>
    <ul>
      <li parentName="ul">{`Would like to come back cause spent a half day here`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.greenbusthailand.com/website/en/"
        }}>{`Green Bus`}</a>{` - VIP seats, coach bus with a toilet`}</li>
    </ul>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/WcN6DcbKpNXTdbz89"
        }}>{`Melt in your mouth`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-3"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7CcCixKQpQ7SuGJc8"
        }}>{`Wat Rong Khun (White Temple)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Get off at "Old Terminal" the stop before Chiang Rai (if taking Green Bus)`}</li>
          <li parentName="ul">{`Really unique temple. Recommend sunglasses due to glare. Everything closes at noon for a lunch break.`}</li>
          <li parentName="ul">{`You can purchase a "leaf" to hang on a tree (to make a wish) if desired`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/6m1jYSW2KJ6FwBFLA"
        }}>{`Wat Rong Suea Ten (Blue Temple)`}</a>{` - Smaller than the White Temple and was built in the 90s
ZZZ`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      